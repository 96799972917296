input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none !important;
}
.nav-header .logo-abbr {
  max-width: 55px;
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}
.picker__container {
  right: 0 !important;
  left: unset !important;
  top: 0;
}
.range-picker-input {
  width: 300px !important;
}
.percentage-bar {
  padding: 0;
  max-height: 40px !important;
  display: flex;
  justify-content: end;
  padding-right: 1rem;
  margin-bottom: 10px;
  background-color: lightgray !important;
  border-radius: 5px !important;
}
.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}
.react-tel-input .country-list {
  width: 250px !important;
}
.search-input {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}
.bgl-theme {
  background: #ffda63;
  border-color: #ffda63;
}
.rdrCalendarWrapper {
  position: absolute;
  z-index: 1;
  right: 30px;
}
.image-view-modal img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  /* max-width: none; */
}
.cross {
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background-color: #ecf0f3 !important;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: unset;
}
.cross:hover {
  background-color: #fff !important;
}
