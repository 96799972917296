/* General Styling */
.details-page {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

.details-container {
  display: flex;
  gap: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.rating-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Left Section */
.left-section {
  text-align: center;
  flex: 1;
  border-right: 1px solid #eaeaea;
  padding-right: 20px;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  opacity: 0.5;
}

.name {
  font-size: 20px;
  font-weight: bold;
}

.designation {
  color: #6c757d;
  font-size: 14px;
  margin: 5px 0;
}

.member-since {
  font-size: 12px;
  color: #888;
  margin-bottom: 15px;
}
.docs {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.doc-section h3 {
  text-align: start;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
}
.doc-img {
  height: 100px;
  width: 90px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #eaeaea;
}
/* Right Section */
.right-section {
  flex: 2;
  padding-left: 20px;
}

.info-section {
  /* margin-bottom: 30px; */
  border-bottom: 1px solid #eaeaea;
}

.info-section h3 {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.info-section p {
  margin: 0;
}
.info-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.info-row div {
  flex: 1;
}

.full-width {
  flex: 100%;
}

/* Tags Section */
.tags-section {
  margin-top: 20px;
}
.tags-section h3 {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  border: 1px solid #ddd;
}

.tag .close-icon {
  font-size: 12px;
  color: #aaa;
}

.tag:hover {
  background-color: #e0e0e0;
  border-color: #ccc;
}
